/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function TypeTaches() {
    //------------FORMULAIRE -----------------
    const [taches, settaches] = useState("")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const saveData = () => {

    }
    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t("Tâches")}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("Paramétrage Type Tâche")}</li>
                    </ol>
                </nav>
            </div>

            <div className="main-wrapper container">
                <div className='row'>
                    <div className="col-lg-8">
                        <div className="card ">
                            <div className="card-body">
                                <h5 className="card-title">{t("Tableau type tâches")}</h5>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className='text-center'>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Type tâches")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='text-center'>
                                                <td>Flower</td>
                                                <td>
                                                    <a href={"/Editmaintenance&code=1"}> <i className="material-icons-outlined text-primary mr-2" style={{ cursor: "pointer" }}>edit</i></a>
                                                    <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} >delete</i></td>
                                            </tr>
                                            <tr className='text-center'>
                                                <td>Flower</td>
                                                <td>
                                                    <a href={"/Editmaintenance&code=1"}> <i className="material-icons-outlined text-primary mr-2" style={{ cursor: "pointer" }}>edit</i></a>
                                                    <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} >delete</i></td>
                                            </tr>
                                            <tr className='text-center'>
                                                <td>Flower</td>
                                                <td>
                                                    <a href={"/Editmaintenance&code=1"}> <i className="material-icons-outlined text-primary mr-2" style={{ cursor: "pointer" }}>edit</i></a>
                                                    <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} >delete</i></td>
                                            </tr>
                                            <tr className='text-center'>
                                                <td>Flower</td>
                                                <td>
                                                    <a href={"/Editmaintenance&code=1"}> <i className="material-icons-outlined text-primary mr-2" style={{ cursor: "pointer" }}>edit</i></a>
                                                    <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} >delete</i></td>
                                            </tr>
                                            <tr className='text-center'>
                                                <td>Flower</td>
                                                <td>
                                                    <a href={"/Editmaintenance&code=1"}> <i className="material-icons-outlined text-primary mr-2" style={{ cursor: "pointer" }}>edit</i></a>
                                                    <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} >delete</i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='card'>
                            <div className='card-header'> {t("ajouter types tâches")} </div>
                            <div className='card-body'>
                                <form>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label>{t("Type tâches")} : </label>
                                            <input type="text" className='form-control' value={taches} onChange={(e) => settaches(e.target.value)} required placeholder='Types tâches ...' />
                                        </div>

                                    </div>
                                    <div className='col-12 mt-3 '>
                                        <button type='sumbit' className='btn btn-primary float-right' onClick={() => saveData()}> {t('Enregistrer')} </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
