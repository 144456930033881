import React from 'react'
import Footer from '../Shared/Footer'

export default function EmptyLayout(props) {
  return (
    <div>
        <React.Fragment>{props.children}</React.Fragment>
        <Footer />

    </div>
  )
}
