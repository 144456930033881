//import dotenv from 'dotenv'
import dotenv from 'dotenv'
import axios from "axios"

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL



/**
 * Authentification Web Service 
 * @returns 
 */
 export async function login (email, password){
     
    try{
        const data = {
            email : email,
            password: password
        }
        const response = await axios.post(url+"users/login",data,{
            IsEssential: true
          });
          return response;
    }catch(error){
        return error?.response
    }
}   

/** LOGOUT USERS */

 export const logout = () => {
    localStorage.removeItem("user");
  };