import moment from 'moment'
import React from 'react'

export default function AnnoncesDashboard({ today, t, listeAnnonces }) {
    return (
        <div className="col-lg-3">
            <div className="card ">
                <div className="card-body">
                    <h5 className="card-title">{t("Annonces")}<span className="card-title-helper">Mois: {moment(today).format('MMMM')}</span></h5>
                    <div className="top-products-list">
                        {listeAnnonces?.map((items, keys) => (
                            <div className="product-item mb-3" key={keys}>
                                <h6>{items.libelle} </h6>
                                <span className='text-primary'>De la part de : {items.user} </span>
                            </div>
                        ))}

                    
                    </div>
                </div>
            </div>
        </div>

    )
}
