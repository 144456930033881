/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader'
import { updateannoncesByCode } from '../../services/annonces/AnnoncesApi'

export default function TableauAnnonce({ t, listeAnnonces, setok, setloading, loading, setindex, setok2, setcode, ok2, index, handleChange }) {
    const updateData = (index) => {
        const clonedData = [...listeAnnonces]
        const code = clonedData[index].code
        const updated = clonedData[index]
        const data ={
            code : updated?.code,
            libelle : updated?.libelle,
            date : updated?.date
        }
        updateannoncesByCode(code, data).then((result) => {
            if (result?.data?.success === 1) {
                setok(true)
                setok2(false)
                setindex("")
                toast("Modification avec succès", { position: "top-right", type: "success" })
            } else {
                toast("Erreur de modification", { position: "top-right", type: "error" })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    return (
        <div className="card ">
            <div className="card-body">
                <h5 className="card-title">{t("Tableau d'annonces")}</h5>
                <div className="table-responsive">
                    <table className="table ">
                        <thead>
                            <tr className='text-center'>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("utilisateur")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t('Date')}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("annonce")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <Loader /> :
                                listeAnnonces?.map((items, keys) => (
                                    <tr className={
                                        keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                    } key={keys}>
                                        {ok2 && index === keys ? (<>
                                            <td>{items.user}</td>
                                            <td><input type="date" className='form-control' name="date" value={moment(items.date).format("YYYY-MM-DD")} onChange={(e) => handleChange(e, keys)} /> </td>
                                            <td><textarea type="text" className='form-control' value={items.libelle} onChange={(e) => handleChange(e, keys)} name="libelle" /> </td>
                                            <td>
                                                <a onClick={() => { setok2(true); setindex(keys); updateData(keys) }}> <i className='fas fa-save fa-2x text-primary mt-2' style={{ cursor: "pointer" }} /></a></td>
                                        </>) :
                                            (<>
                                                <td>{items.user}</td>
                                                <td>{moment(items.date).format("DD/MM/YYYY")} </td>
                                                <td>{items.libelle}</td>
                                                <td>
                                                    <a onClick={() => { setok2(true); setindex(keys) }}> <i className='fas fa-edit fa-2x text-info mt-2' style={{ cursor: "pointer" }} /> </a>
                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }}> <i className='fas fa-trash fa-2x text-danger mt-2' style={{ cursor: "pointer" }} /> </a>
                                                </td>
                                            </>)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
