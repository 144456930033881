/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function Header() {
    //---------- History --------------
    const history = useHistory();
    // ----- Language global selectionné
    const language = Cookies.get('locale')
    const [lg, setLg] = useState(language === 'fr' ? 'Français' : language === "ar" ? "Arabe" : "English")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    { language === "ar" ? require('moment/locale/ar-tn') : language === "fr" ? require('moment/locale/fr') : require('moment/locale/en-au') }
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result

    // SELECT CHANGE LANGUAGES 
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        if (language === 'ar') {
            setLg('Arabe')
        } else if (language === 'fr') {
            setLg('Français')
        } else if (language === 'en') {
            setLg('English')
        }
        Cookies.set('locale', language)
    }
    const seDeconnecter = () => {
        
        localStorage.removeItem("user");
        history.push({
            pathname: "/login"
        })
    }
    return (
        <div className="page-header">
            <nav className="navbar navbar-expand container">
                <div className="logo-box"><a href="#" className="logo-text">Support Clediss</a></div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <ul className="navbar-nav">
                    <li className="nav-item small-screens-sidebar-link">
                        <a href="#" className="nav-link"><i className="material-icons-outlined">menu</i></a>
                    </li>
                    <li className="nav-item nav-profile dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="../../assets/images/avatars/profile-image-1.png" alt="profile image" />
                            <span>{details?.nom}</span><i className="material-icons dropdown-icon">keyboard_arrow_down</i>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="/profile">  {t('profile')} </a>
                            <div className="dropdown-divider" />
                            <button className="dropdown-item " onClick={()=>seDeconnecter()}>{t('Se déconnecter')}</button>
                        </div>
                    </li>


                </ul>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a href="#" className="nav-link"><i className="material-icons-outlined">notifications</i></a>
                        </li>
                        <li className="nav-item">
                            <select
                                className="form-control text-primary  " style={{ fontSize: "10px", height: "41px" }}
                                value={language}
                                onChange={(e) =>
                                    changeLanguage(e.target.value)
                                } >
                                <option value="fr" > Français</option>
                                <option value="ar"> العربية</option>
                                <option value="en"> English </option>

                            </select>
                        </li>
                    </ul>
                </div>
                <div className="navbar-search">
                    <form>
                        <div className="form-group">
                            <input type="text" name="search" id="nav-search" placeholder="Search..." />
                        </div>
                    </form>
                </div>
            </nav>
        </div>

    )
}
