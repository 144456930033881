import React from 'react'

export default function Footer() {
    return (
        <div className="page-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <span className="footer-text">2022 © CLEDISS</span>
                    </div>
                </div>
            </div>
        </div>

    )
}
