/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify"
import { login } from '../../services/Authentification/LoginApi'
import { useForm } from "react-hook-form";

export default function Authentification() {

    const history = useHistory()
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    // -------------- Loading -----------
    const [loading, setLoading] = useState(false)

    const { register, handleSubmit } = useForm();

    const handleRegistration = (data) => {
        console.log(data)
        if (data.email !== "" && data.password !== "") {
            setLoading(true)
            login(data.email, data.password)
                .then((result) => {
                    console.log(result)
                    if (result?.data?.success === 0) {
                        toast(t("Mot de passe ou email invalide"), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        localStorage.setItem("user", JSON.stringify(result.data));
                        toast(t("Bienvenue "), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                   /*      var user = JSON.parse(localStorage.getItem('user'));
                        var details = user?.result */
                        history.push({
                            pathname: '/'
                        })

                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="auth-page sign-in">
            <div className="connect-container align-content-stretch d-flex flex-wrap">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-5">
                            <div className="auth-form">
                                <ToastContainer />

                                <div className="row">
                                    <div className="col">
                                        <div className="logo-box"><a href="#" className="logo-text">Support Clediss</a></div>
                                        <form onSubmit={handleSubmit(handleRegistration)} >
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"{...register('email')} />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control" id="password" placeholder="Password" {...register('password')} />
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block btn-submit" >{t("S'identifier")}</button>
                                            <div className="auth-options">
                                                <div className="custom-control custom-checkbox form-group">
                                                    <input type="checkbox" className="custom-control-input" id="exampleCheck1" />
                                                    <label className="custom-control-label" htmlFor="exampleCheck1">Remember me</label>
                                                </div>
                                                <a href="#" className="forgot-link">S'inscrire</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block d-xl-block">
                            <div className="auth-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

