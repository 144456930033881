/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useState } from 'react'
import Loader from '../../components/Loader'

export default function TableauMaintenance({ t, listeMaintenance, loading , setcode }) {
    const [index, setindex] = useState("")
    const [show, setShow] = useState(false)
    return (
        <div className="card ">
            <div className="card-body">
                <h5 className="card-title"> <i className='fas fa-table text-info mr-2 ' />  {t("Tableau Maintenance")}</h5>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr className='text-center'>
                                <th scope="col">#</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Support")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Heure Début")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Heure Fin")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t('Date')}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Client")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Societé")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Application")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Validation")}</th>
                                <th scope="col" style={{ fontSize: "10px" }} >{t("Action")}</th>
                            </tr>
                        </thead>
                        {loading ? <Loader /> : (
                            <tbody>
                                {listeMaintenance?.map((items, keys) => (
                                    <>
                                        <tr className='text-center' key={keys}>
                                            <td className='fs-10'> {index === keys && show ? <i className="material-icons " onClick={() => { setindex(""); setShow(false) }}>expand_less</i> : <i className="material-icons " onClick={() => { setindex(keys); setShow(true) }}>expand_more</i>} </td>
                                            <td>{items.user}</td>
                                            <td>{items.heure_debut}</td>
                                            <td>{items.heure_fin}</td>
                                            <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                            <td>{items.client}</td>
                                            <td>{items.societe}</td>
                                            <td>{items.projet}</td>
                                            <td>{items?.validation === 1 ? <span className="badge badge-success">{t("Résolue")}</span> : <span className="badge badge-danger">{t("Non Résolue")}</span>} </td>
                                            <td>
                                                <a href={"/Editmaintenance&code=" + items.code}>  <i className='fas fa-edit fa-2x text-info mt-2 mr-2' style={{ cursor: "pointer" }} /></a>
                                                <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }} ><i className='fas fa-trash fa-2x text-danger mt-2' style={{ cursor: "pointer" }}  /></a></td>
                                        </tr>
                                        {index === keys && show ?
                                            <tr>
                                                <td colSpan="10">
                                                    <label className='text-primary mr-2'><b>Problème : </b></label>
                                                    <span>{items.probleme} </span>
                                                </td>
                                            </tr> : null
                                        }
                                    </>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    )
}
