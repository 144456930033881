/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader'
import { getSelectClients, getSelectProjets } from '../../services/maintenance/MaitenanceAPI'
import AddModalMaintenance from '../Maintenance/AddModalMaintenance'

export default function TableauMaintenance({ t, listeMaintenance, loading }) {

    const [index, setindex] = useState("")
    const [show, setShow] = useState(false)
    const [listeClients, setlisteClients] = useState([])
    const [listeProjets, setlisteProjets] = useState([])

    const [ok, setok] = useState(false)

    useEffect(()=>{
        getSelectProjets()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteProjets(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
        getSelectClients()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteClients(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
       
    },[ok])
    return (
        <div className="col-lg-9">
            <div className="card card-transactions">
                <div className="card-body">
                    <div className='row'>
                        <div className='col-6'>  <h5 className="card-title mt-3">{t("Tableau Maintenance")}
                    </h5></div>
                        <div className='col-6'>   <a href='' className='float-right btn btn-primary'  style={{fontSize:"10px"}} data-toggle="modal" data-target=".bd-example-modal-lg" ><i class="fas fa-address-book"></i> Ajouter Maintenance</a></div>
                    </div>
                   <ToastContainer/>
                 
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr style={{ fontSize: "10px" }}>
                                    <th scope="col">#</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Support")}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Heure Début")}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Heure Fin")}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t('Date')}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Client")}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Societé")}</th>
                                    <th scope="col" style={{ fontSize: "10px" }} >{t("Application")}</th>
                                </tr>
                            </thead>
                            {loading ? <Loader /> : (
                                <tbody>
                                    {listeMaintenance?.map((items, keys) => (
                                        <>
                                            <tr className='text-center' key={keys}>
                                                <td className='fs-10'> {index === keys && show ? <i className="material-icons " onClick={() => { setindex(""); setShow(false) }}>expand_less</i> : <i className="material-icons " onClick={() => { setindex(keys); setShow(true) }}>expand_more</i>} </td>
                                                <td>{items.user}</td>
                                                <td>{items.heure_debut}</td>
                                                <td>{items.heure_fin}</td>
                                                <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                                <td>{items.client}</td>
                                                <td>{items.societe}</td>
                                                <td>{items.projet}</td>

                                            </tr>
                                            {index === keys && show ?
                                                <tr>
                                                    <td colSpan="5">
                                                        <label className='text-primary mr-2'><b>Problème : </b></label>
                                                        <span>{items.probleme} </span>
                                                    </td>
                                                    <td colSpan="3">
                                                        <label className='text-primary mr-2'><b>{t("Validation")} : </b></label>

                                                        {items?.validation === 1 ? <span className="badge badge-success">{t("Résolue")}</span> : <span className="badge badge-danger">{t("Non Résolue")}</span>}

                                                    </td>
                                                </tr> : null
                                            }
                                        </>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            <AddModalMaintenance t={t} listeClients={listeClients} listeProjets={listeProjets} ok={ok} setok={setok} />

        </div>
    )
}
