import React from 'react'
import Footer from '../Shared/Footer'
import Header from '../Shared/Header'
import HeaderMenu from '../Shared/HeaderMenu'


export default function OthersPages(props) {
  return (
    <div className="connect-container align-content-stretch d-flex flex-wrap">
      <div className="page-container">
        <Header />
          <Header />
          <HeaderMenu />
            <React.Fragment>{props.children}</React.Fragment>
      
          <Footer />
      </div>

    </div>
  )
}
