import React from 'react'

export default function CreateTask() {
  return (
    <div>
    <div className="modal fade bd-example-modal-lg"  id="addTask" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Ajouter une tâche</h5>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">


                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='row'>
                        <div className='col-12'>
                        <h5 className="modal-title" id="addTask">  <input type="text" value={"Titre"} className="form-control mb-2" /> </h5>

                            <textarea className='form-control ' placeholder='Description .... ' />
                        </div>

                        <div className='col-4 mt-2'>
                        <select className='form-control float-right'>
                            <option>Affectation tâche</option>
                            <option>User1</option>
                            <option>User2</option>
                            <option>User3</option>

                        </select>
                       
                    </div>
                    <div className='col-4 mt-2'>
                    <select className='form-control float-right'>
                            <option>Type Tâche</option>
                            <option>A Faire</option>
                            <option>En cours</option>
                            <option>Terminé</option>

                        </select>
                    </div>
                    <div className='col-4 mt-2'>
                        <label className='mr-2 '>Etiquette :  </label>
                    <input type="color" value="#e66465" />
                    </div>

                </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                    <button type="button" className="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
    )
</div>
  )
}
