import React from 'react'
import { toast } from 'react-toastify';
import { deletemaintenanceByCode } from '../../services/maintenance/MaitenanceAPI';

export default function DeleteModalMaintenance({ code, setok, t }) {

    const deleteMaintenance = (code) => {
        deletemaintenanceByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast('Ligne supprimé avec succès', { type: "success", position: "top-right" })
                    setok(true)
                } else {
                    toast('Erreur de suppression !', { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }
  return (
    <div className="modal fade" id="modal-notification" tabIndex={-1} role="dialog" aria-labelledby="modal-notification" aria-hidden="true" >
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content border-0">
            <div className="modal-header">
                <h2 className="modal-title" id="modal-title-notification">Supprimer Ligne Maintenance : {code} </h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok(true)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i className="ti-trash fa-3x" />
                    <h4 className="heading mt-4">Êtes-vous sur ?</h4>
                    <p>Êtes-vous sur  de vouloir supprimer le client ?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => { deleteMaintenance(code) }}> Confirmer </button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setok(true)}>Fermer</button>
            </div>
        </div>
    </div>
</div>)
}
