/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import { addSociete, getListeSocietes, updateSocieteByCode } from '../../services/parametrage/ParmetrageAPI';
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment"
import ModalDeleteSociete from './ModalDeleteSociete';

export default function ParametrageSociete() {
    //Formulaire    
    const { register, handleSubmit } = useForm();
    const [code, setcode] = useState("")
    //Liste SOCIETE 
    const [listeSociete, setlisteSociete] = useState([])
    //loading
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const [ok, setok] = useState(false) // OK => refresh tableau
    const [index, setindex] = useState("")
    const [ok2, setok2] = useState(false)

    useEffect(() => {
        getListeSocietes().then((result) => {
            if (result?.data?.success === 1) {
                setlisteSociete(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [ok])

    const updateData = (index) => {
        const clonedData = [...listeSociete]
        const code = clonedData[index].code
        const updated = clonedData[index]
        updateSocieteByCode(code, updated).then((result) => {
            if (result?.data?.success === 1) {
                setok(true)
                setok2(false)
                setindex("")
                toast("Modification avec succès", { position: "top-right", type: "success" })
            } else {
                toast("Erreur de modification", { position: "top-right", type: "error" })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }

    const handleChange = (e, index) => {
        const clonedData = [...listeSociete]
        clonedData[index][e.target.name] = e.target.value
        setindex(index)
        setlisteSociete(clonedData)
    }

    const handleRegistration = (data) => {
        Object.assign(data, {
            code: "Ste-" + parseInt(Date.now() * Math.random())
        })
        console.log(data)
        const arr = []
        arr.push(data)
        addSociete(arr).then((result) => {
            if (result?.data?.success === 1) {
                setloading(true)
                toast("Ajout avec succès ", { type: "success", position: "top-right" })
                setok(!ok)
            } else {
                toast("Erreur d'ajout ", { type: "error", position: "top-right" })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t("parametrage")}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("Paramétrage Societes")}</li>
                    </ol>
                </nav>
            </div>

            <div className="main-wrapper container">
                <div className='row'>
                    <ToastContainer />
                    <div className="col-lg-9">
                        <div className="card ">
                            <div className="card-body">
                                <h5 className="card-title"> <i className='fas fa-table text-info mr-2 ' /> {t("liste des societes")}
                                    {/*                                 <button className='btn btn-success float-right mb-2' style={{fontSize:"10px"}}><i className='fas fa-file-csv mr-2 fa-2x' /> {t("Importer")}</button>
 */}                               </h5>
                                <div className="table-responsive" style={{ overflow: 'auto', height: '600px' }}>
                                    <table className="table ">
                                        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                            <tr className='text-center'>
                                                <th scope="col-2" style={{ fontSize: "10px" }} >{t("Societé")}</th>
                                                <th scope="col-1" style={{ fontSize: "10px" }} >{t("Date Début")}</th>
                                                <th scope="col-1" style={{ fontSize: "10px" }} >{t("Date Fin")}</th>
                                                <th scope="col-2" style={{ fontSize: "10px" }} >{t("Actif")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <Loader /> :
                                                listeSociete?.map((items, keys) => (
                                                    <tr className={
                                                        keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                                    } key={keys}>
                                                        {ok2 && index === keys ? (
                                                            <>
                                                                <td><input type="text" value={items.libelle} className="form-control" style={{ fontSize: "9px" }}
                                                                    onChange={(e) => handleChange(e, keys)} name="libelle" /></td>
                                                                <td>
                                                                    <input type="date" value={moment(items.dateExpir_debut).format('YYYY-MM-DD')} className="form-control" style={{ fontSize: "9px" }}
                                                                        onChange={(e) => handleChange(e, keys)} name="dateExpir_debut" />
                                                                </td>
                                                                <td>
                                                                    <input type="date" value={moment(items.dateExpir_fin).format('YYYY-MM-DD')} className="form-control" style={{ fontSize: "9px" }}
                                                                        onChange={(e) => handleChange(e, keys)} name="dateExpir_fin" />
                                                                </td>
                                                                <td>
                                                                    <select value={items.isactif} onChange={(e) => handleChange(e, keys)} name="isactif" className="form-control" style={{ fontSize: "9px" }}>
                                                                        <option value={1}>{t('Actif')}</option>
                                                                        <option value={0}>{t('Inactif')}</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <a onClick={() => { setok2(true); setindex(keys); updateData(keys) }}> <i className='fas fa-save fa-2x text-primary mt-2' style={{ cursor: "pointer" }} /></a>
                                                                    {/*                <i className="material-icons-outlined text-danger mr-2" style={{ cursor: "pointer" }} onClick={() => { setok2(false); setindex("") }} >close</i> */}</td>

                                                            </>
                                                        ) : (
                                                            <>
                                                                <td>{items.libelle}</td>
                                                                <td>{moment(items.dateExpir_debut).format("DD/MM/YYYY")}</td>
                                                                <td>{moment(items.dateExpir_fin).format("DD/MM/YYYY")}</td>
                                                                <td><i className={items.isactif === 1 ? "material-icons text-success mr-2" : "material-icons text-danger mr-2"} style={{ cursor: "pointer" }}>fiber_manual_record</i></td>

                                                                <td>
                                                                    <a onClick={() => { setok2(true); setindex(keys) }}> <i className='fas fa-edit fa-2x text-info mt-2' style={{ cursor: "pointer" }} /> </a>
                                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }}> <i className='fas fa-trash fa-2x text-danger mt-2' style={{ cursor: "pointer" }} /> </a>
                                                                </td>
                                                            </>
                                                        )}

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className='card'>
                            <div className='card-header'> {t("ajouter societe")} </div>
                            <div className='card-body'>
                                <form onSubmit={handleSubmit(handleRegistration)}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label>{t("Societé")} : </label>
                                            <input type="text" className='form-control' {...register('libelle')} required placeholder='Nom Societé' />
                                        </div>
                                        <div className='col-12'>
                                            <label>{t("Date Début")} : </label>
                                            <input type="date" className='form-control' {...register('dateExpir_debut')} required placeholder={t("Date Début")} />
                                        </div> <div className='col-12'>
                                            <label>{t("Date Fin")} : </label>
                                            <input type="date" className='form-control' {...register('dateExpir_fin')} required placeholder='Nom Societé' />
                                        </div>
                                    </div>
                                    <div className='col-12 mt-3 '>
                                        <button type='sumbit' className='btn btn-primary float-right'> {t('Enregistrer')} </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDeleteSociete code={code} setok={setok} t={t} />

        </div>
    )
}
