import dotenv from 'dotenv'
import axios from "axios"
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;
const url = REACT_APP_API_URL

/**
 * ------------------------------ PARAMETRAGE MAINTENANCE ----------------------------
 */
/**
 * GET LISTE maintenance 
 * @returns 
 */
export async function getListemaintenance(datedebut,datefin) {
    try {
        const response = await axios.get(url + "maintenance/liste/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * GET LISTE maintenance 
 * @returns 
 */
 export async function getSelectClients() {
    try {
        const response = await axios.get(url + "maintenance/selectclient", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
export async function getSelectProjets() {
    try {
        const response = await axios.get(url + "maintenance/selectprojet", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * GET maintenance BY CODE
 * @param {*} code 
 * @returns 
 */
export async function getmaintenanceByCode(code) {
    try {
        const response = await axios.get(url + "maintenance/code/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * UPDATE maintenance BY CODE
 * @param {*} code 
 * @returns 
 */
export async function updatemaintenanceByCode(code, data) {
    try {
        const response = await axios.patch(url + "maintenance/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * DELETE maintenance BY CODE
 * @param {*} code 
 * @returns 
 */
export async function deletemaintenanceByCode(code) {
    try {
        const response = await axios.delete(url + "maintenance/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * ADD maintenance 
 * @param {*} code 
 * @returns 
 */
export async function addmaintenance(data) {
    try {
        const response = await axios.post(url + "maintenance/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}