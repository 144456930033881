import dotenv from 'dotenv'
import axios from "axios"
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;
const url = REACT_APP_API_URL

/**
 * GET LISTE ANNONCES
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
export async function getListeAnnonces(datedebut,datefin) {
    try {
        const response = await axios.get(url + "dashboard/listeannonces/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}

export async function getCardDahboard(datedebut,datefin) {
    try {
        const response = await axios.get(url + "dashboard/cardDashboard/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}