
export function getSession(value) {
    return localStorage.getItem(value);
  }
/**
 * check if user is connected
 * @returns
 */

  /**
 * check if user is connected
 * @returns
 */
export const isLogin = () => {
  if (getSession('user') !== null) {
    return true;
  }
  else{ return false};
};

export const isLoginAndAdmin = ()=>{

if (getSession('user') !== null !== null && getSession("user").role_code==="admin") {
  return true;
}
else{ return false};

}