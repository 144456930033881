import React from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'

export default function ModalAddClient({ t, handleSubmit, handleSave, register, listeSociete, searchInput, handleChangeSociete,setok }) {
    return (
        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Ajouter Maintenance</h5>
                        <button type="button"  onClick={() => setok(true)} className="close" data-dismiss="modal" aria-label="Close">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(handleSave)}>
                            <div className='row'>
                                <div className='col-12'>
                                    <label>{t("Client")} : </label>
                                    <input type="text" className='form-control' {...register('libelle')} required placeholder='Nom et prénom client' />
                                </div>
                                <div className='col-12'>
                                    <label>{t("email")} : </label>
                                    <input type="email" className='form-control' {...register('email')} required placeholder='E-Mail' />
                                </div>
                                <div className='col-12'>
                                    <label>{t("actif")} : </label>
                                    <select name="isactif" className="form-control"  {...register('isactif')} >
                                        <option value={1}>{t('Actif')}</option>
                                        <option value={0}>{t('Inactif')}</option>

                                    </select>
                                </div>
                                <div className='col-12'>
                                    <label> {t("Societé")} : </label>
                                    <SelectSearch
                                        options={listeSociete}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeSociete}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Choisir Societe')}
                                    />

                                </div>
                            </div>
                            <div className='col-12 mt-3 '>
                                <button type='sumbit' className='btn btn-primary float-right'> {t('Enregistrer')} </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
