/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CardDashboard from './CardDashboard';
import AnnoncesDashboard from './AnnoncesDashboard';
import TableauMaintenance from './TableauMaintenance';
import { useTranslation } from 'react-i18next';
import { getCardDahboard, getListeAnnonces } from '../../services/dashboard/DashboarAPI';
import { getListemaintenance } from '../../services/maintenance/MaitenanceAPI';

export default function TableaudeBord() {
    //-------------- TODAY -----------------------
    const [today, settoday] = useState(moment().format('YYYY-MM-DD'))
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    const [listeAnnonces, setlisteAnnonces] = useState([])
    const [listeMaintenance, setlisteMaintenance] = useState([])
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState({
        totalAppel : 0,
        totalSocietes : 0,
        totalSupport : 0
    })
    const [dateTime, setDateTime] = useState({
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds()
    });
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const onChangeDateFormat = () => {

    }
    useEffect(() => {
        getListeAnnonces(date1, date2).then((result) => {
            if (result?.data?.success === 1) {
                setlisteAnnonces(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getCardDahboard(date1, date2).then((result) => {
            if (result?.data?.success === 1) {
                setdata({
                    totalAppel : result?.data?.totalAppel?.totalAppel,
                    totalSocietes : result?.data?.totalSocietes?.totalSociete,
                    totalSupport : result?.data?.totalSupport?.totalSupport
                })
            }
        }).catch((err) => {
            console.log(err)
        });
        getListemaintenance(date1, date2).then((result) => {
            if(result?.data?.success === 1){
                setlisteMaintenance(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
        const timer = setInterval(() => {
            const date = new Date();
            setDateTime({
                hours: date.getHours(),
                minutes: date.getMinutes(),
                seconds: date.getSeconds()
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [])

    return (
        <div className="page-content">
            <div className="page-info container">
                <div className="row">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#"> {moment().format(" dddd LL")} : <b className='text-primary'>{dateTime.hours}:{dateTime.minutes}:{dateTime.seconds} <i className='fa fa-clock-o ' />  </b></a></li>
                            </ol>
                        </nav>
                        <div className="page-options">
                            <div className="input-group">
                                <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary fs-12" />
                                <span className="input-group-append">
                                    <button className="btn btn-primary btn-sm" type="button" onClick={() => onChangeDateFormat()}><span className="material-icons mt-2" >
                                        search
                                    </span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-wrapper container">
                <CardDashboard t={t} data={data}/>
                <div className="row">
                    <TableauMaintenance t={t} listeMaintenance={listeMaintenance} loading={loading} />
                    <AnnoncesDashboard today={today} listeAnnonces={listeAnnonces} t={t} />
                </div>
            </div>
        </div>

    )
}
