/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import '../../style.css'
import { useForm } from "react-hook-form";
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { addmaintenance } from '../../services/maintenance/MaitenanceAPI';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function AddModalMaintenance({ t, listeClients ,listeProjets ,setok , ok}) {
    const today = new Date()
    var time = today.getHours() + ":" + today.getMinutes() 
     // form validation rules 
     const validationSchema = Yup.object().shape({
        heure_debut: Yup.string()
            .required('Heure Début est obligatoire'),
            heure_fin: Yup.string()
            .required('Heure Fin est obligatoire'),
            date: Yup.string()
            .required('Date est obligatoire')

    });
    const formOptions = { resolver: yupResolver(validationSchema)  };
    //Formulaire    
    const { register, handleSubmit , reset, formState} =useForm(formOptions);
    const { errors } = formState;

    //USE REF
    const searchInput = useRef();
    const [codeClient, setcodeClient] = useState("")
    const [codeApplication, setcodeApplication] = useState("")
    const [resolue, setresolue] = useState(false) // False : NON RESOLUE - True : Resolue
    // user state for form
    const [maintenance, setmaintenance] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    
      // effect runs on component mount
      useEffect(() => {
        // simulate async api call with set timeout
        setTimeout(() => setmaintenance({ heure_debut: time, heure_fin: time, date: moment(today).format("YYYY-MM-DD"), canal:"" }), 1000);
    }, []);

     // effect runs when user state is updated
     useEffect(() => {
        // reset form with user data
        reset(maintenance);
    }, [maintenance]);
    // Handle Change Client
    const handleChangeClient = (...args) => {
        setcodeClient(args[1].value)
    };

    const handleSave = (data) => {
        Object.assign(data,{
            code : "M-"+ parseInt(Date.now() * Math.random()),
            validation : resolue,
            client_code: codeClient,
            projet_code : codeApplication,
            user_code: details?.code
        })
        const arr= []
        arr.push(data)
        addmaintenance(arr)
        .then((result) => {
            if(result?.data?.success===1){
                toast("Ajout avec succès",{type:"success",position:"top-right"})
                setTimeout(() => setmaintenance({ heure_debut: "", heure_fin: "", date:"" }), 1000);
                setcodeClient("")
                setcodeApplication("")
                setresolue(false)
                setok(!ok)
            }else {
                toast("Erreur ",{type:"error",position:"top-right"})
            }
        }).catch((err) => {
            console.log(err)
        });
        console.log(data)

    }
    return (
        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Ajouter Maintenance</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(handleSave)}>
                            <div className='row'>
                                <div className='col-6'>
                                    <label >{t("Heure Début")}</label>
                                    <input type="time" className='form-control'   {...register('heure_debut')} required />
                                </div>
                                <div className='col-6'>
                                    <label className='label-control'>{t("Heure Fin")}</label>
                                    <input type="time" className='form-control' {...register('heure_fin')} required />
                                </div>
                                <div className='col-12'>
                                    <label className='label-control'>{t('Date')}</label>
                                    <input type="date" className='form-control'{...register('date')} required />
                                </div>
                                <div className='col-12'>
                                    <label className='label-control'>{t('Canal')}</label>
                                    <input type="text" className='form-control'{...register('canal')} required placeholder='Whatsapp , E-mail , Téléphone ...' />
                                </div>
                                <div className='col-12'>
                                    <label >{t('Client')}</label>
                                    <SelectSearch
                                        options={listeClients}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeClient}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Choisir un client')}
                                    />
                                    
                                </div>
                                <div className='col-12'>
                                    <label >{t('Application')}</label>
                                    <select className="select2 form-control" value={codeApplication} onChange={(e) => setcodeApplication(e.target.value)} required>
                                        <optgroup label="Clients">
                                            <option value={""}>Choisir un projet</option>
                                            {listeProjets?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </optgroup>
                                    </select>
                                </div>
                                <div className='col-12'>
                                    <label >{t('Problème')}</label>
                                    <textarea type="text" className='form-control' placeholder='Probléme .... ' {...register('probleme')} />
                                </div>
                                <div className='col-12'>
                                    <label >{t('Validation')}</label>
                                    <div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" defaultValue id="defaultCheck1" value={resolue} onChange={(e) => setresolue(e.target.checked)} />
                                            <label className="custom-control-label" htmlFor="defaultCheck1">
                                                {resolue ? "Résolue" : "Non Résolue"}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Close')}</button>
                                        <button className='btn btn-primary float-right' type="submit" >{t('Enregistrer')} </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    )
}
