import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { isLogin } from '../utils/SessionsUtils';
export default function PrivateRoute({ component: Component, layout: Layout, ...rest }) {

  const history = useHistory()
  //const user = JSON.parse(localStorage.getItem('user'));
  //const details = user?.result
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {isLogin() ? <Component {...props}></Component> : <>{history.push({ pathname: '/login' })}</>}
        </Layout>
      )}
    ></Route>
  );
}
