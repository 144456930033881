//import dotenv from 'dotenv'
import dotenv from 'dotenv'
import axios from "axios"
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;
const url = REACT_APP_API_URL

/**
 * ------------------------------ PARAMETRAGE CLIENTS ----------------------------
 */
/**
 * GET LISTE CLIENTS 
 * @returns 
 */
export async function getListeClients() {
    try {
        const response = await axios.get(url + "clients", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * GET CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function getClientByCode(code) {
    try {
        const response = await axios.get(url + "clients/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * UPDATE CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function updateClientByCode(code, data) {
    try {
        const response = await axios.patch(url + "clients/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * DELETE CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function deleteClientByCode(code) {
    try {
        const response = await axios.delete(url + "clients/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * ADD CLIENT 
 * @param {*} code 
 * @returns 
 */
export async function addClient(data) {
    try {
        const response = await axios.post(url + "clients/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * ------------------------------ PARAMETRAGE SOCIETES ----------------------------
 */
/**
 * GET LISTE CLIENTS 
 * @returns 
 */
 export async function getListeSocietes() {
    try {
        const response = await axios.get(url + "societes", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * GET LISTE CLIENTS 
 * @returns 
 */
 export async function getSelectedSociete() {
    try {
        const response = await axios.get(url + "societes/liste/selected", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * GET CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function getSocieteByCode(code) {
    try {
        const response = await axios.get(url + "societes/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * UPDATE CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function updateSocieteByCode(code, data) {
    try {
        const response = await axios.patch(url + "societes/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * DELETE CLIENT BY CODE
 * @param {*} code 
 * @returns 
 */
export async function deleteSocieteByCode(code) {
    try {
        const response = await axios.delete(url + "societes/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * ADD Societe 
 * @param {*} code 
 * @returns 
 */
export async function addSociete(data) {
    try {
        const response = await axios.post(url + "societes/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}

