import React from 'react'

export default function CardDashboard({t ,data}) {
  return (
    <div className="row stats-row">
                    <div className="col-lg-4 col-md-12">
                        <div className="card card-transparent stats-card">
                            <div className="card-body">
                                <div className="stats-info">
                                    <h5 className="card-title fs-12">{data?.totalSupport}</h5>
                                    <p className="stats-text">{t("Equipe Support Actifs")}</p>
                                </div>
                                <div className="stats-icon change-success ">
                                    <i className="material-icons">perm_identity</i>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="card card-transparent stats-card">
                            <div className="card-body">
                                <div className="stats-info">
                                    <h5 className="card-title">{data?.totalAppel} </h5>
                                    <p className="stats-text">{t("Nombre d'heure d'appels")}</p>
                                </div>
                                <div className="stats-icon change-success">
                                    <i className="material-icons">alarm</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="card card-transparent stats-card">
                            <div className="card-body">
                                <div className="stats-info">
                                    <h5 className="card-title">{data?.totalSocietes}</h5>
                                    <p className="stats-text">{t("Total Societés")}</p>
                                </div>
                                <div className="stats-icon change-success">
                                    <i className="material-icons">store</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  )
}
