/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

export default function ProfileUser() {
    return (
        <div className="page-content">
            <div className="main-wrapper container">
                <div className="profile-header">
                    <div className="row">
                        <div className="col">
                            <div className="profile-img">
                                <img src="../../assets/images/avatars/profile-image-1.png" />
                            </div>
                            <div className="profile-name">
                                <h3>Nancy Moore</h3>
                                <span>Member of Envato</span>
                            </div>
                            <div className="profile-menu">
                                <ul>
                                    <li>
                                        <a href="#">Feed</a>
                                    </li>
                                    <li>
                                        <a href="#">About</a>
                                    </li>
                                    <li>
                                        <a href="#">Friends</a>
                                    </li>
                                    <li>
                                        <a href="#">Photos</a>
                                    </li>
                                    <li>
                                        <a href="#">Videos</a>
                                    </li>
                                    <li>
                                        <a href="#">Music</a>
                                    </li>
                                </ul>
                                <div className="profile-status">
                                    <i className="active-now" /> Active now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-content">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="post">
                                        <div className="post-header">
                                            <img src="../../assets/images/avatars/profile-image-3.jpg" />
                                            <div className="post-info">
                                                <span className="post-author">Riley Beach</span><br />
                                                <span className="post-date">3hrs</span>
                                            </div>
                                            <div className="post-header-actions">
                                                <a href="#"><i className="fas fa-ellipsis-h" /></a>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Proin eu fringilla dui. Pellentesque mattis lobortis mauris eu tincidunt. Maecenas hendrerit faucibus dolor, in commodo lectus mattis ac.</p>
                                            <img src="../../assets/images/post-1.jpg" className="post-image" alt />
                                        </div>
                                        <div className="post-actions">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <a href="#" className="like-btn"><i className="far fa-heart" />Like</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="far fa-comment" />Comment</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fas fa-share" />Share</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="post-comments">
                                            <div className="post-comm">
                                                <img src="../../assets/images/avatars/profile-image-2.png" className="comment-img" />
                                                <div className="comment-container">
                                                    <span className="comment-author">
                                                        Sonny Rosas
                                                        <small className="comment-date">5min</small>
                                                    </span>
                                                </div>
                                                <span className="comment-text">
                                                    Mauris ultrices convallis massa, nec facilisis enim interdum ac.
                                                </span>
                                            </div>
                                            <div className="post-comm">
                                                <img src="../../assets/images/avatars/profile-image.png" className="comment-img" />
                                                <div className="comment-container">
                                                    <span className="comment-author">
                                                        Jacob Lee
                                                        <small className="comment-date">27min</small>
                                                    </span>
                                                </div>
                                                <span className="comment-text">
                                                    Cras tincidunt quam nisl, vitae aliquet enim pharetra at. Nunc varius bibendum turpis, vitae ultrices tortor facilisis ac.
                                                </span>
                                            </div>
                                            <div className="new-comment">
                                                <form action="javascript: void(0)">
                                                    <div className="input-group">
                                                        <input type="text" name="comment" className="form-control search-input" placeholder="Type something..." />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button" id="button-addon1">Comment</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="post">
                                        <div className="post-header">
                                            <img src="../../assets/images/avatars/profile-image.png" />
                                            <div className="post-info">
                                                <span className="post-author">Haydon Kenny</span><br />
                                                <span className="post-date">7hrs</span>
                                            </div>
                                            <div className="post-header-actions">
                                                <a href="#"><i className="fas fa-ellipsis-h" /></a>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Praesent purus purus, varius ac mauris quis, scelerisque ornare massa. Nam volutpat lacinia lectus, vitae mattis nisl. Mauris ultrices convallis massa, nec facilisis enim interdum ac. Suspendisse hendrerit risus nec nisi dictum efficitur. Pellentesque aliquet in justo id lobortis.</p>
                                        </div>
                                        <div className="post-actions">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <a href="#" className="like-btn"><i className="far fa-heart" />Like</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="far fa-comment" />Comment</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fas fa-share" />Share</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="post-comments">
                                            <div className="post-comm">
                                                <img src="../../assets/images/avatars/profile-image-2.png" className="comment-img" />
                                                <div className="comment-container">
                                                    <span className="comment-author">
                                                        Riley Beach
                                                        <small className="comment-date">1hr</small>
                                                    </span>
                                                </div>
                                                <span className="comment-text">
                                                    Fusce mattis fermentum quam non porta...
                                                </span>
                                            </div>
                                            <div className="new-comment">
                                                <form action="javascript: void(0)">
                                                    <div className="input-group">
                                                        <input type="text" name="comment" className="form-control search-input" placeholder="Type something..." />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button" id="button-addon1">Comment</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">About</h5>
                                    <p>Quisque vel tellus sit amet quam efficitur sagittis. Fusce aliquam pulvinar suscipit.</p>
                                    <ul className="list-unstyled profile-about-list">
                                        <li><i className="material-icons">school</i><span>Studied Mechanical Engineering at <a href="#">Carnegie Mellon University</a></span></li>
                                        <li><i className="material-icons">work</i><span>Former manager at <a href="#">Stacks</a></span></li>
                                        <li><i className="material-icons">my_location</i><span>From <a href="#">Boston, Massachusetts</a></span></li>
                                        <li><i className="material-icons">rss_feed</i><span>Followed by 716 people</span></li>
                                        <li>
                                            <button className="btn btn-block btn-primary m-t-lg">Follow</button>
                                            <button className="btn btn-block btn-secondary m-t-lg">Message</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Contact Info</h5>
                                    <ul className="list-unstyled profile-about-list">
                                        <li><i className="material-icons">mail_outline</i><span>jay.morton@gmail.com</span></li>
                                        <li><i className="material-icons">home</i><span>Lives in <a href="#">San Francisco, CA</a></span></li>
                                        <li><i className="material-icons">local_phone</i><span>+1 (678) 290 1680</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
