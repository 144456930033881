/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CardViewTask from './CardViewTask';
import CreateTask from './CreateTask';

export default function ToDoPage() {

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const [ok1, setok1] = useState(true) // ALL
    const [ok2, setok2] = useState(false) //A faire
    const [ok3, setok3] = useState(false) // En cours
    const [ok4, setok4] = useState(false) // Terminé

    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t("Tâches")}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("Liste des tâches")}</li>
                    </ol>
                </nav>
            </div>
            <div className="main-wrapper container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="todo-sidebar">
                                    <div className="todo-new-task">
                                        <button className="btn btn-primary btn-block" data-toggle="modal" data-target="#addTask" >Ajouter une tâche</button>
                                        <div className="modal fade" id="newTask" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">New Task</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <i className="material-icons">close</i>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" id="new-task-name" placeholder="Task Name" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                        <button type="button" className="btn btn-primary">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="todo-menu">
                                        <ul className="list-unstyled">
                                            <li className="active"><a href="#" onClick={() => { setok1(true); setok2(false); setok3(false); setok4(false) }}><i className="fas fa-bars" />Tout</a></li>
                                            <li><a href="#" onClick={() => { setok1(false); setok2(true); setok3(false); setok4(false) }}><i className="fas fa-check" />A Faire</a></li>
                                            <li><a href="#" onClick={() => { setok1(false); setok2(false); setok3(true); setok4(false) }}><i className="fas fa-check" />En Cours</a></li>
                                            <li><a href="#" onClick={() => { setok1(false); setok2(false); setok3(false); setok4(true) }}><i className="fas fa-trash" />Terminé</a></li>
                                        </ul>
                                    </div>
                                    <div className="divider" />
                                    <div className="todo-search">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" id="todo-search" className="form-control" placeholder="Search task" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="todo-list">
                                    {ok1 ? <ul className="list-unstyled">
                                        <li>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <a href="javascript: void(0);" className="custom-checkbox" data-toggle="modal" data-target=".bd-example-modal-lg">
                                                        <input type="checkbox" className="custom-control-input" id="task1" />
                                                        <label className="custom-control-label" htmlFor="task1" />
                                                        Go to shop
                                                    </a>

                                                </div>

                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="task2" />
                                                <label className="custom-control-label" htmlFor="task2" />
                                                Finish java assignment
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="task3" />
                                                <label className="custom-control-label" htmlFor="task3" />
                                                Send e-mail to Dr. Collins
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="task4" />
                                                <label className="custom-control-label" htmlFor="task4" />
                                                Delete all folders in ../assets/plugins
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox done">
                                                <input type="checkbox" className="custom-control-input" id="task5" defaultChecked />
                                                <label className="custom-control-label" htmlFor="task5" />
                                                Sell iPad Mini
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox done">
                                                <input type="checkbox" className="custom-control-input" id="task6" defaultChecked />
                                                <label className="custom-control-label" htmlFor="task6" />
                                                Create new Amazon account
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);" className="custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="task7" />
                                                <label className="custom-control-label" htmlFor="task7" />
                                                Check new codes from section #7 student assignments
                                            </a>
                                        </li>
                                    </ul> : ok2 ?
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="javascript: void(0);" className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="task1" />
                                                    <label className="custom-control-label" htmlFor="task1" />
                                                    Go to shop
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript: void(0);" className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="task2" />
                                                    <label className="custom-control-label" htmlFor="task2" />
                                                    Finish java assignment
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript: void(0);" className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="task3" />
                                                    <label className="custom-control-label" htmlFor="task3" />
                                                    Send e-mail to Dr. Collins
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript: void(0);" className="custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="task4" />
                                                    <label className="custom-control-label" htmlFor="task4" />
                                                    Delete all folders in ../assets/plugins
                                                </a>
                                            </li>

                                        </ul> : ok3 ?
                                            <ul className="list-unstyled">
                                                <li>
                                                    <a href="javascript: void(0);" className="custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="task1" />
                                                        <label className="custom-control-label" htmlFor="task1" />
                                                        Go to shop
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);" className="custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="task2" />
                                                        <label className="custom-control-label" htmlFor="task2" />
                                                        Finish java assignment
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript: void(0);" className="custom-checkbox done">
                                                        <input type="checkbox" className="custom-control-input" id="task5" defaultChecked />
                                                        <label className="custom-control-label" htmlFor="task5" />
                                                        Sell iPad Mini
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);" className="custom-checkbox done">
                                                        <input type="checkbox" className="custom-control-input" id="task6" defaultChecked />
                                                        <label className="custom-control-label" htmlFor="task6" />
                                                        Create new Amazon account
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);" className="custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="task7" />
                                                        <label className="custom-control-label" htmlFor="task7" />
                                                        Check new codes from section #7 student assignments
                                                    </a>
                                                </li>
                                            </ul> : ok4 ?
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a href="javascript: void(0);" className="custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="task1" />
                                                            <label className="custom-control-label" htmlFor="task1" />
                                                            Go to shop
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript: void(0);" className="custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="task2" />
                                                            <label className="custom-control-label" htmlFor="task2" />
                                                            Finish java assignment
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript: void(0);" className="custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="task3" />
                                                            <label className="custom-control-label" htmlFor="task3" />
                                                            Send e-mail to Dr. Collins
                                                        </a>
                                                    </li>
                                                </ul> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CardViewTask />
                <CreateTask />
            </div>
        </div>

    )
}
