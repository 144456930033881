/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function HeaderMenu() {

    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD") //Start Month
    var date2 = moment(lastDay).format("YYYY-MM-DD") // End Month
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    return (
        <div className="horizontal-bar">
            <div className="logo-box"><a href="#" className="logo-text">Support Clediss</a></div>
            <a href="#" className="hide-horizontal-bar"><i className="material-icons">close</i></a>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="horizontal-bar-menu">
                            <ul>
                                <li><a href="/" className="active">{t("Dashboard")}</a></li>
                                <li>
                                    <a href="#">{t("Maintenance")}<i className="material-icons">keyboard_arrow_down</i></a>
                                    <ul>
                                        <li>
                                            <a href={'/maintenance&datedebut=' + date1 + '&datefin=' + date2}>{t("Tableau Maintenance")}</a>
                                        </li>
                                        <li>
                                            <a href="/parametrageClient">{t("Paramétrage Client")}</a>
                                        </li>
                                        <li>
                                            <a href="/parametrageSociete">{t("Paramétrage Societes")}</a>
                                        </li>

                                    </ul>
                                </li>
                                <li><a href="#">{t('Tâches')}<i className="material-icons">keyboard_arrow_down</i></a>
                                    <ul>
                                        <li>
                                            <a href="/taches">{t("Liste des tâches")}</a>
                                        </li>
                                      {/*   <li>
                                            <a href="/parametrageTaches">{t("Paramétrage Type Tâche")}</a>
                                        </li> */}
                                    </ul>
                                </li>
                                <li><a href="#">{t("Annonces")}<i className="material-icons">keyboard_arrow_down</i></a>
                                    <ul>
                                        <li>
                                            <a href={'/annonces&datedebut=' + date1 + '&datefin=' + date2}>{t("Tableau d'annonces")}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
