/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader'
import { getmaintenanceByCode, getSelectClients, getSelectProjets, updatemaintenanceByCode } from '../../services/maintenance/MaitenanceAPI'

export default function EditPageMaintenance() {
    const { code } = useParams()
    //Formualire 
    const [heure_debut, setheure_debut] = useState("")
    const [heure_fin, setheure_fin] = useState("")
    const [date, setdate] = useState("")
    const [codeClient, setcodeClient] = useState("")
    const [listeClients, setlisteClients] = useState([])
    const [codeApplication, setcodeApplication] = useState("")
    const [listeApplications, setlisteApplications] = useState([])
    const [probleme, setprobleme] = useState("")
    const [resolue, setresolue] = useState(false) // False : NON RESOLUE - True : Resolue
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

   const updateClient = () => {
        const data = {
            heure_debut : heure_debut,heure_fin : heure_fin,probleme :probleme ,date : date ,validation: resolue === true ? 1 : 0
        }
        if(codeClient!== ""){
            Object.assign(data,{
                client_code : codeClient
            })
        }
        if(probleme!== ""){
            Object.assign(data,{
                projet_code : probleme
            })
        }
        updatemaintenanceByCode(code, data).then((result) => {
            console.log(result?.data?.success)
            if(result?.data?.success===1){
                toast("Modification aves succès",{type:"success",position:"top-right" })
            }else {
                toast("Erreur de modification",{type:"error",position:"top-right" })
            }
        }).catch((err) => {
            console.log(err)
        });

   }

    useEffect(()=>{
        setloading(true)
        getSelectProjets()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteApplications(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
        getSelectClients()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteClients(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
        getmaintenanceByCode(code).then((result) => {
            if(result?.data?.success===1){
                var {client_code,projet_code ,heure_debut,heure_fin,probleme ,date,validation} =result?.data?.data[0]
                setcodeClient(client_code)
                setcodeApplication(projet_code)
                setheure_debut(heure_debut)
                setheure_fin(heure_fin)
                setprobleme(probleme)
                setdate(moment(date).format("YYYY-MM-DD"))
                setresolue(validation=== 1 ? true : false)

            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    },[])

    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t('Maintenance')}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('Modifier Maintenance')}</li>
                    </ol>
                </nav>
            </div>
            <ToastContainer />
            <div className="main-wrapper container">
                <div className='card'>
                    <div className='card-header'>{t('Modifier Maintenance')}</div>
                    <div className='card-body'>
                        {loading ? <Loader /> : 
                        
                        <form>
                        <div className='row'>
                            <div className='col-6'>
                                <label >{t("Heure Début")}</label>
                                <input type="time" className='form-control' value={heure_debut} onChange={(e) => setheure_debut(e.target.value)} required />
                            </div>
                            <div className='col-6'>
                                <label className='label-control'>{t("Heure Fin")}</label>
                                <input type="time" className='form-control' value={heure_fin} onChange={(e) => setheure_fin(e.target.value)} required />
                            </div>
                            <div className='col-12'>
                                <label className='label-control'>{t('Date')}</label>
                                <input type="date" className='form-control' value={date} onChange={(e) => setdate(e.target.value)} required />
                            </div>
                       
                            <div className='col-12'>
                                <label >{t('Client')}</label>
                                <select className="select2 form-control" value={codeClient} onChange={(e) => setcodeClient(e.target.value)} >
                                        <option value={""}>Choisir un client</option>
                                        {listeClients?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='col-12'>
                                <label >{t('Application')}</label>
                                <select className=" form-control" value={codeApplication} onChange={(e) => setcodeApplication(e.target.value)} >
                                    <optgroup label="Applications">
                                    <option value={""}>Choisir une Application</option>
                                        {listeApplications?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                            <div className='col-12'>
                                <label >{t('Problème')}</label>
                                <textarea type="text" className='form-control' value={probleme} onChange={(e)=>setprobleme(e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label >{t('Validation')}</label>
                                <div>
                                    <div className="custom-control custom-checkbox">
                                        <input className="custom-control-input" type="checkbox" defaultChecked={resolue} id="defaultCheck1" value={resolue} onChange={(e) => setresolue(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="defaultCheck1">
                                            {resolue ? "Résolue" : "Non Résolue"}
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="modal-footer">
                                    <button className='btn btn-primary float-right' type="button" onClick={()=>updateClient()}>{t('Enregistrer')} </button>
                                </div>
                            </div>
                        </div>
                    </form>
                        }
                       
                    </div>
                </div>
            </div>

        </div>
    )
}
