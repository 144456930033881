import TableaudeBord from './pages/Dashboard/TableaudeBord';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import OthersPages from './layouts/OthersPages';
import PublicRoute from './routes/PublicRoute';
import EmptyLayout from './layouts/EmptyLayout';
import ListeMaintenance from './pages/Maintenance/ListeMaintenance';
import EditPageMaintenance from './pages/Maintenance/EditPageMaintenance';
import ListeAnnonces from './pages/Annonces/ListeAnnonces';
import ProfileUser from './pages/profile/ProfileUser';
import ToDoPage from './pages/taches/ToDoPage';
import ParametrageClient from './pages/parametrage/ParametrageClient';
import ParametrageSociete from './pages/parametrage/ParametrageSociete';
import TypeTaches from './pages/taches/TypeTaches';
import Authentification from './pages/Authentification/Login';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/**-------------------------------- PAGE Tableau de bord ------------------------------- */}
        <PrivateRoute layout={OthersPages} component={TableaudeBord} exact path="/" />
        {/**-------------------------------- Annonces ------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeAnnonces} exact path="/annonces&datedebut=:datedebut&datefin=:datefin" />
        {/**-------------------------------- Maintenance ------------------------------- */}
        <PrivateRoute layout={OthersPages} component={EditPageMaintenance} exact path="/Editmaintenance&code=:code" />
        <PrivateRoute layout={OthersPages} component={ListeMaintenance} exact path="/maintenance&datedebut=:datedebut&datefin=:datefin" />
        {/** --------------------------------PARAMETRAGE ---------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ParametrageClient} exact path="/parametrageClient" />
        <PrivateRoute layout={OthersPages} component={ParametrageSociete} exact path="/parametrageSociete" />
         {/**-------------------------------- Tâches ------------------------------- */}
         <PrivateRoute layout={OthersPages} component={ToDoPage} exact path="/taches" />         
         <PrivateRoute layout={OthersPages} component={TypeTaches} exact path="/parametrageTaches" />
        {/**-------------------------------- USERS ------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ProfileUser} exact path="/profile" />
        {/**-------------------------------- PAGE Auhtentification ------------------------------- */}
        <PublicRoute layout={EmptyLayout} component={Authentification} exact path="/login" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
