/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
//--------------- Historique -----------------------
import { useHistory, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { getListemaintenance, getSelectClients, getSelectProjets } from '../../services/maintenance/MaitenanceAPI';
import AddModalMaintenance from './AddModalMaintenance';
import DeleteModalMaintenance from './DeleteModalMaintenance';
//--------------- Maintenance ---------------------
import TableauMaintenance from './TableauMaintenance'

export default function ListeMaintenance() {
    const { datedebut, datefin } = useParams()
    const [listeMaintenance, setlisteMaintenance] = useState([])
    const [listeClients, setlisteClients] = useState([])
    const [listeProjets, setlisteProjets] = useState([])
    const [code, setcode] = useState("")
    const [ok, setok] = useState(false)
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(()=>{
        getSelectProjets()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteProjets(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
        getSelectClients()
        .then((result) => {
                if(result?.data?.success===1){
                    setlisteClients(result?.data?.data)
                }
        }).catch((err) => {
            console.log(err)
        });
        getListemaintenance(datedebut,datefin).then((result) => {
            if(result?.data?.success === 1){
                setlisteMaintenance(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    },[ok, listeMaintenance])

    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t("Maintenance")}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('Tableau Maintenance')}</li>
                    </ol>
                </nav>
                <div className="page-options">
                    <a href="#" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg" ><i class="fas fa-address-book"></i> Ajouter Maintenance</a>
                </div>
            </div>
            <ToastContainer />
            <div className="main-wrapper container">

                <div className='row'>
                    <div className="col-lg-12">
                        <TableauMaintenance t={t} listeMaintenance={listeMaintenance} loading={loading} setcode={setcode} />
                    </div>

                </div>
                <AddModalMaintenance t={t} listeClients={listeClients} listeProjets={listeProjets} ok={ok} setok={setok} />
            </div>
            <DeleteModalMaintenance code={code} setok={setok} t={t} />

        </div>
    )
}
