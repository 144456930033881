import dotenv from 'dotenv'
import axios from "axios"
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;
const url = REACT_APP_API_URL


/**
 * ------------------------------ PARAMETRAGE Annonces ----------------------------
 */
/**
 * GET LISTE annonces 
 * @returns 
 */
 export async function getListeAnnonces() {
    try {
        const response = await axios.get(url + "annonces/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}

/**
 * GET annonces BY CODE
 * @param {*} code 
 * @returns 
 */
export async function getannoncesByCode(code) {
    try {
        const response = await axios.get(url + "annonces/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * UPDATE annonces BY CODE
 * @param {*} code 
 * @returns 
 */
export async function updateannoncesByCode(code, data) {
    try {
        const response = await axios.patch(url + "annonces/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * DELETE annonces BY CODE
 * @param {*} code 
 * @returns 
 */
export async function deleteannoncesByCode(code) {
    try {
        const response = await axios.delete(url + "annonces/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}
/**
 * ADD annonces 
 * @param {*} code 
 * @returns 
 */
export async function addannonces(data) {
    try {
        const response = await axios.post(url + "annonces/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error?.response
    }
}