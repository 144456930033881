import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { addannonces } from '../../services/annonces/AnnoncesApi';

export default function AjouterAnnonce({ t ,setok ,ok}) {
    
    const { register, handleSubmit } = useForm();
    //Formulaire 
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
   
   

    const handleSave = (data) => {
        console.log(details)
        Object.assign(data,{
            code : "A-"+ parseInt(Date.now() * Math.random()),
            user_code: details?.code
        })
        const arr= []
        arr.push(data)
        addannonces(arr)
        .then((result) => {
            if(result?.data?.success===1){
                toast("Ajout avec succès",{type:"success",position:"top-right"})
                setok(!ok)
            }else {
                toast("Erreur ",{type:"error",position:"top-right"})
            }
        }).catch((err) => {
            console.log(err)
        });
        console.log(data)

    }

    return (
        <div className='card'>
            <div className='card-header'> {t("Ajouter une annonce")} </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className='row'>
                        <div className='col-12'>
                            <label> {t('Date')} : </label>
                            <input type="date" className='form-control' {...register('date')} required />
                        </div>
                      
                        <div className='col-12'>
                            <label> {t('annonce')} : </label>
                            <textarea className='form-control'{...register('libelle')}required />
                        </div>
                    </div>
                    <div className='col-12 mt-3 '>
                    <button type='sumbit' className='btn btn-primary float-right'> {t('Enregistrer')} </button>
                    </div>
                </form>

            </div>

        </div>
    )
}
