/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { addClient, getListeClients, getSelectedSociete } from '../../services/parametrage/ParmetrageAPI';
import '../../style.css'
import Loader from '../../components/Loader';
import { toast, ToastContainer } from 'react-toastify';
import ModalDeleteClient from './ModalDeleteClient';
import ModalAddClient from './ModalAddClient';
export default function ParametrageClient() {
    //Formulaire
    const [code, setcode] = useState("")
    const [codeSociete, setcodeSociete] = useState("")
    const [listeSociete, setlisteSociete] = useState([])
    const [listeClients, setlisteClients] = useState([])
    //USE REF
    const searchInput = useRef();
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //loading
    const [loading, setloading] = useState(true)
    const [ok, setok] = useState(false) // OK => refresh tableau
    const [index, setindex] = useState("")
    const [ok2, setok2] = useState(false)

    //Formulaire    
    const { register, handleSubmit } = useForm();
    const saveData = () => {

    }
    useEffect(() => {
        getSelectedSociete().then((result) => {
            if (result?.data?.success === 1) {
                setlisteSociete(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListeClients().then((result) => {
            if (result?.data?.success === 1) {
                setlisteClients(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })

    }, [ok])
    const handleSave = (data) => {
        Object.assign(data, {
            code: "CL-" + parseInt(Date.now() * Math.random()),
            societe_code: codeSociete
        })
        console.log(data)
        const arr = []
        arr.push(data)
        addClient(arr).then((result) => {
            if (result?.data?.success === 1) {
                setloading(true)
                toast("Ajout avec succès ", { type: "success", position: "top-right" })
                setok(!ok)
            } else {
                toast("Erreur d'ajout ", { type: "error", position: "top-right" })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    // Handle Change Societe
    const handleChangeSociete = (...args) => {
        setcodeSociete(args[1].value)
    };
    const handleChange = (e, index) => {
        const clonedData = [...listeSociete]
        clonedData[index][e.target.name] = e.target.value
        setindex(index)
        setlisteSociete(clonedData)
    }

    return (
        <div className="page-content">
            <div className="page-info container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{t("parametrage")}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("Paramétrage Client")}</li>
                    </ol>
                </nav>
                <div className="page-options">
                    <a href="#" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg" ><i class="fas fa-address-book"></i> Ajouter Client</a>
                </div>
            </div>

            <div className="main-wrapper container">
                <div className='row'>
                    <ToastContainer />
                    <div className="col-lg-12">
                        <div className="card ">
                            <div className="card-body">
                                <h5 className="card-title"> <i className='fas fa-table text-info mr-2 ' /> {t("liste des clients")}</h5>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr className='text-center'>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Client")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Societé")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("email")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Actif")}</th>
                                                <th scope="col" style={{ fontSize: "10px" }} >{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <Loader /> :
                                                listeClients?.map((items, keys) => (
                                                    <tr className={
                                                        keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                                    } key={keys}>
                                                        {ok2 && index === keys ? (
                                                            <>
                                                                <td><input type="text" value={items.libelle} className="form-control" style={{ fontSize: "9px" }}
                                                                    onChange={(e) => handleChange(e, keys)} name="libelle" /></td>
                                                                <td> <select value={items.societe_code} onChange={(e) => handleChange(e, keys)} name="societe_code" className="form-control" style={{ fontSize: "9px" }}>
                                                                    <option value={""}>{t('Choisir une societe')}</option>
                                                                    {listeSociete?.map((items, keys) => (
                                                                        <option key={keys} value={items.value} >{items.name}</option>
                                                                    ))}
                                                                </select></td>
                                                                <td> <input type="text" value={items.email} className="form-control" style={{ fontSize: "9px" }}
                                                                    onChange={(e) => handleChange(e, keys)} name="email" /> </td>
                                                                <td> <select value={items.isactif} onChange={(e) => handleChange(e, keys)} name="isactif" className="form-control" style={{ fontSize: "9px" }}>
                                                                        <option value={1}>{t('Actif')}</option>
                                                                        <option value={0}>{t('Inactif')}</option>
                                                                    </select></td>
                                                                <td>
                                                                    <a onClick={() => { setok2(true); setindex(keys) }} > <i className='fas fa-edit fa-2x text-info mt-2' style={{ cursor: "pointer" }} /></a>
                                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }}> <i className='fas fa-trash fa-2x text-danger mt-2' style={{ cursor: "pointer" }} /> </a>
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td>{items.libelle}</td>
                                                                <td>{items.societe}</td>
                                                                <td>{items.email}</td>
                                                                <td><i className={items.isactif === 1 ? "material-icons text-success mr-2" : "material-icons text-danger mr-2"} style={{ cursor: "pointer" }}>fiber_manual_record</i></td>

                                                                <td>
                                                                    <a onClick={() => { setok2(true); setindex(keys) }} > <i className='fas fa-edit fa-2x text-info mt-2' style={{ cursor: "pointer" }} /></a>
                                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }}> <i className='fas fa-trash fa-2x text-danger mt-2' style={{ cursor: "pointer" }} /> </a>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  {/*   <div className="col-lg-3">
                        <div className='card'>
                            <div className='card-header'> {t("ajouter clients")} </div>
                            <div className='card-body'>
                               
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <ModalAddClient t={t} handleSubmit={handleSubmit} handleSave={handleSave} register={register} listeSociete={listeSociete} searchInpu={searchInput} handleChangeSociete={handleChangeSociete}  setok={setok}/>
            <ModalDeleteClient code={code} setok={setok} t={t} />

        </div>
    )
}
